import axios from "axios";
import HttpRequest from "../../HttpService";
import { store } from "redux/store";
import { LanguageHelper } from "helpers/LanguageHelper";
export class PaginationOptions {
  per_page = 10;
  order_by_column = "id";
  order_by_direction = "desc";
  page = 1;
}

export default class VoxMeFeatureApiService extends HttpRequest {
  constructor() {
    super(
      axios.create({
        baseURL: `${process.env.REACT_APP_VOXME_API_FEATURE}/v1`,
      })
    );

    this.axios.interceptors.request.use((config) => {
      const state = store.getState();
      if (
        !config.headers["Authorization"] &&
        state.auth &&
        state.auth.authToken
      ) {
        config.headers["Authorization"] = state.auth.authToken;
      }

      if (state.i18n.language) {
        config.headers["Content-Language"] = LanguageHelper.getNormalizedCode(
          state.i18n.language
        );
      }

      return config;
    });
  }
}

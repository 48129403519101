import queryString from "querystring";

export class SubscriptionFlowHelper {
  static isSignFlow() {
    return window.location.pathname.split("/")[2] === "sign";
  }

  static isPhoneType() {
    const queryParams = queryString.parse(
      window.location.search.replace("?", "")
    );
    return queryParams.identifier === "phone";
  }

  static isPartnershipFlow() {
    return !!new URLSearchParams(window.location.search).get("pass");
  }
}

import Auth from "@aws-amplify/auth";
import PubSub from "@aws-amplify/pubsub";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

class AwsIotService {
  static configure() {
    Auth.configure({
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL,
      region: process.env.REACT_APP_AWS_IOT_REGION,
      identityPoolRegion: process.env.REACT_APP_AWS_IOT_REGION,
    });

    PubSub.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_AWS_IOT_REGION,
        aws_pubsub_endpoint: `wss://${process.env.REACT_APP_AWS_IOT_ENDPOINT}/mqtt`,
      })
    );
  }
}

export default AwsIotService;

import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import ReactLoading from "react-loading";

const MyAccount = lazy(() =>
  import("../containers").then((module) => ({ default: module.MyAccount }))
);
const AccountUsageSettings = lazy(() =>
  import("../containers").then((module) => ({
    default: module.AccountUsageSettings,
  }))
);

const NewCheck = lazy(() => import("../containers/MyAccount/CheckMe/NewCheck"));
const MyChecks = lazy(() => import("../containers/MyAccount/CheckMe/MyChecks"));
const EditChecks = lazy(() =>
  import("../containers/MyAccount/CheckMe/EditChecks")
);

const CheckMeDetails = lazy(() =>
  import("../containers/MyAccount/CheckMe/CheckMeDetails")
);

const NewFollow = lazy(() =>
  import("../containers/MyAccount/FollowMe/NewFollow")
);

const MyFollows = lazy(() =>
  import("../containers/MyAccount/FollowMe/MyFollows")
);

const EditFollow = lazy(() =>
  import("../containers/MyAccount/FollowMe/EditFollow")
);

const FollowMeDetails = lazy(() =>
  import("../containers/MyAccount/FollowMe/FollowMeDetails")
);

const RemindMeDetails = lazy(() =>
  import("../containers/MyAccount/RemindMe/RemindMeDetails")
);

const MyReminds = lazy(() =>
  import("../containers").then((module) => ({
    default: module.MyReminds,
  }))
);
const NewRemind = lazy(() =>
  import("../containers/MyAccount/RemindMe/NewRemind")
);

const EditRemind = lazy(() =>
  import("../containers/MyAccount/RemindMe/EditRemind")
);

const Summary = lazy(() => import("../containers/MyAccount/Summary"));

const Layout = lazy(() => import("../containers/MyAccount/Layout"));
const Error404 = lazy(() =>
  import("../components").then((module) => ({ default: module.Error404 }))
);
const Contacts = lazy(() => import("../containers/MyAccount/Contacts"));
const History = lazy(() => import("../containers/MyAccount/History"));
const NewUsers = lazy(() => import("../containers/Subscription/NewUsers"));
const UpdateUser = lazy(() => import("../containers/MyAccount/UpdateUser"));

const AssistedSelector = lazy(() =>
  import("../components").then((module) => ({
    default: module.AssistedSelector,
  }))
);

class PrivateRouter extends React.Component {
  render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to="/" />;
    }
    const { currentLanguage } = this.props;

    const automaticRedirect = this.props.permissions.assisted
      ? `/${currentLanguage}/admin/account-usage`
      : `/${currentLanguage}/admin/my-account`;
    return (
      <Suspense
        fallback={
          <ReactLoading
            className="loading"
            type="bars"
            color="#111"
            height={"100%"}
            width={"100%"}
          />
        }
      >
        <Layout {...this.props}>
          <AssistedSelector />
          <Switch>
            {this.props.permissions.account && (
              <Route exact path={`/${currentLanguage}/admin/my-account`}>
                <MyAccount {...this.props} />
              </Route>
            )}

            {this.props.permissions.account && (
              <Route exact path={`/${currentLanguage}/admin/new-users`}>
                <NewUsers {...this.props} />
              </Route>
            )}

            <Route exact path={`/${currentLanguage}/admin/user`}>
              <UpdateUser {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/contacts`}>
              <Contacts {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/history`}>
              <History {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/summary`}>
              <Summary {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/new-check`}>
              <NewCheck {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-checks`}>
              <MyChecks {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-checks/edit/:id`}>
              <EditChecks {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-checks/:id`}>
              <CheckMeDetails {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/new-follow`}>
              <NewFollow {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-follows`}>
              <MyFollows {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-follows/edit/:id`}>
              <EditFollow {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-follows/:id`}>
              <FollowMeDetails {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-reminds`}>
              <MyReminds {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/my-reminds/:id/`}>
              <RemindMeDetails {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/new-remind`}>
              <NewRemind {...this.props} />
            </Route>

            <Route exact path={`/${currentLanguage}/admin/edit-remind/:id`}>
              <EditRemind {...this.props} />
            </Route>

            {this.props.permissions.assisted && (
              <Route exact path={`/${currentLanguage}/admin/account-usage`}>
                <AccountUsageSettings {...this.props} />
              </Route>
            )}

            <Redirect
              exact
              from={`/${currentLanguage}/admin`}
              to={automaticRedirect}
            />

            <Route path="*">
              <Error404 t={this.props.t} />
            </Route>
          </Switch>
        </Layout>
      </Suspense>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoggedIn: !!store.auth.authToken,
    permissions: store.auth.permissions || {},
    paymentGateway: store.auth.userInfo.paymentGateway,
  };
};

export default connect(mapStateToProps)(PrivateRouter);

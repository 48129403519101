import VoxMeApiService from "./VoxMeApiService";
import i18n from "../../i18n";
import { store } from "../../redux/store";
import AuthService from "./AuthService";
import ContactService from "./ContactService";
import { updateUserInfo, updatePermissions } from "../../redux/actions";
import { SubscriptionFlowHelper } from "../../helpers/SubscriptionFlowHelper";
import { IdentifierTypes } from "../../enums";

class SubscriptionService extends VoxMeApiService {
  constructor() {
    super();
    this.authService = new AuthService();
    this.contactsService = new ContactService();
  }

  async subscribe(paymentInfos) {
    const subscriptionData = store.getState().subscription;
    if (
      typeof paymentInfos !== "string" &&
      typeof paymentInfos !== "boolean" &&
      paymentInfos.idDocument
    ) {
      subscriptionData.subscriber.id_document = paymentInfos.idDocument;
    }

    const subscriptionSteps = [
      { method: this.checkMemberEmails.bind(this), input: subscriptionData },
      {
        method: this.createSubscriber.bind(this),
        input: {
          ...subscriptionData.subscriber,
          partnership: typeof paymentInfos === "string",
          freeSub: typeof paymentInfos === "boolean",
        },
      },
      {
        method: this.authenticate.bind(this),
        input: subscriptionData.subscriber,
      },
      {
        method: this.createAssisted.bind(this),
        input: { ...subscriptionData.assisted, send_notifications: false },
      },
    ];

    if (!SubscriptionFlowHelper.isSignFlow()) {
      subscriptionSteps.push({
        method: this.createContacts.bind(this),
        input: {
          contacts: subscriptionData.contacts,
          sendNotifications: false,
        },
      });
    }

    subscriptionSteps.push({
      method:
        typeof paymentInfos === "string"
          ? this.subscribePartnerCustomer.bind(this)
          : typeof paymentInfos === "boolean"
          ? this.subscribeFreeCustomer.bind(this)
          : this.createSubscription.bind(this),
      input: paymentInfos,
    });

    subscriptionSteps.push({
      method: this.updateMemberInfo.bind(this),
      input: null,
    });

    let response = null;
    for (const step of subscriptionSteps) {
      response = await step.method(step.input);
      if (response.error) {
        break;
      }
    }

    response.success = !response.error;
    return response;
  }

  async subscribeFreeCustomer() {
    const response = this.createDefaultResponseObj();

    try {
      await this.createFreeUser();
    } catch (e) {
      response.error = i18n.t(
        "subscription.index.partnershipSubscriptionError"
      );
    }

    return response;
  }

  createFreeUser() {
    const url = "/subscription/subscribe-user-free";

    return this.makeHttpRequest({
      url,
      method: "POST",
      data: {},
    });
  }

  async subscribePartnerCustomer(token) {
    const response = this.createDefaultResponseObj();

    try {
      await this.createUserElegible(token);
    } catch (e) {
      response.error =
        e.response && e.response.status === 401
          ? i18n.t("subscription.index.partnershipSubscriptionTimeout")
          : i18n.t("subscription.index.partnershipSubscriptionError");
    }

    return response;
  }

  createUserElegible(data) {
    const url = "/subscription/subscribe-user-partnership";

    const payload = {
      partner_token: data,
    };

    return this.makeHttpRequest({
      url,
      method: "POST",
      data: payload,
    });
  }

  async newUsers(contacts) {
    const subscriptionData = store.getState().subscription;
    const subscriptionSteps = [
      {
        method: this.createNewAssisted.bind(this),
        input: subscriptionData.subscriber,
      },
      {
        method: this.createContactsNewUser.bind(this),
        input: contacts,
      },
    ];
    let response = null;
    for (const step of subscriptionSteps) {
      response = await step.method(step.input);
      if (response.error) {
        console.log(step);
        console.log(response);
        break;
      }
    }

    response.success = !response.error;
    return response;
  }

  async checkMemberEmails(data) {
    const response = this.createDefaultResponseObj();

    try {
      const subscriberEmailIsAvailable =
        data.subscriber.identifier_type === IdentifierTypes.PHONE &&
        !data.subscriber.email
          ? true
          : await this.emailIsAvailable(
              data.subscriber.email,
              "subscription-payment-subscriber"
            );

      let subscriberPhoneIsAvailable;

      if (data.subscriber.identifier_type === IdentifierTypes.PHONE) {
        subscriberPhoneIsAvailable = await this.phoneIsAvailable(
          data.subscriber.phone,
          "subscription-payment-subscriber"
        );
      }

      const emailExists = subscriberEmailIsAvailable === false;
      const phoneExists = subscriberPhoneIsAvailable === false;

      if (emailExists || phoneExists) {
        response.error = i18n.t(
          `subscription.formMe.${
            emailExists ? "userAlreadyExists" : "userAlreadyExistsPhone"
          }`,
          {
            [emailExists ? "email" : "phone"]: emailExists
              ? data.subscriber.email
              : data.subscriber.phone,
          }
        );

        if (emailExists && phoneExists) {
          response.error = i18n.t(
            "subscription.formMe.userAlreadyExistsEmailAndPhone"
          );
        }
      }

      if (!data.assisted.self_assist) {
        const assistedEmailIsAvailable =
          data.assisted.identifier_type === IdentifierTypes.PHONE &&
          !data.assisted.email
            ? true
            : await this.emailIsAvailable(
                data.assisted.email,
                "subscription-payment-assisted"
              );

        let assistedPhoneIsAvailable;

        if (data.assisted.identifier_type === IdentifierTypes.PHONE) {
          assistedPhoneIsAvailable = await this.phoneIsAvailable(
            data.assisted.phone,
            "subscription-payment-assisted"
          );
        }

        const assistedEmailExists = assistedEmailIsAvailable === false;
        const assistedPhoneExists = assistedPhoneIsAvailable === false;

        if (assistedEmailExists || assistedPhoneExists) {
          response.error = i18n.t(
            `subscription.formMe.${
              assistedEmailExists
                ? "userAlreadyExists"
                : "userAlreadyExistsPhone"
            }`,
            {
              [assistedEmailExists ? "email" : "phone"]: assistedEmailExists
                ? data.assisted.email
                : data.assisted.phone,
            }
          );

          if (assistedEmailExists && assistedPhoneExists) {
            response.error = i18n.t(
              "subscription.formMe.userAlreadyExistsEmailAndPhone"
            );
          }
        }
      }
    } catch (e) {
      response.error = i18n.t("shared.genericError");
    }

    return response;
  }

  async createSubscriber(data) {
    const response = this.createDefaultResponseObj();

    try {
      await this.createMember(data);
    } catch (error) {
      response.error = i18n.t("shared.genericError");
    }

    return response;
  }

  async authenticate(data) {
    const response = this.createDefaultResponseObj();

    try {
      await this.authService.login({
        username:
          data.identifier_type === IdentifierTypes.PHONE
            ? data.phone
            : data.email,
        password: data.password,
        identifier_type: data.identifier_type,
      });
    } catch (error) {
      console.log(error);
      response.error = i18n.t("shared.genericError");
    }

    return response;
  }

  async createAssisted(data) {
    const response = this.createDefaultResponseObj();

    try {
      await super.createAssisted(data);
    } catch (error) {
      this.authService.logout();
      response.error = i18n.t("shared.genericError");
    }

    return response;
  }

  async createNewAssisted(data) {
    const response = this.createDefaultResponseObj();
    try {
      await super.createNewAssisted(data);
    } catch (error) {
      response.error = i18n.t("shared.genericError");
    }
    return response;
  }

  async createContactsNewUser(contacts) {
    const response = this.createDefaultResponseObj();
    try {
      await this.contactsService.handleContactsNewUser(contacts);
    } catch (error) {
      response.error = i18n.t("subscription.contacts.submitError");
    }
    return response;
  }

  async createContacts({ contacts, sendNotifications = true }) {
    const response = this.createDefaultResponseObj();

    try {
      await this.contactsService.handleContacts(
        contacts,
        null,
        sendNotifications
      );
    } catch (error) {
      this.authService.logout();
      response.error = i18n.t("subscription.contacts.submitError");
    }

    return response;
  }

  async createSubscription(payload) {
    const response = this.createDefaultResponseObj();

    try {
      const subscribeResp = await this.makeHttpRequest({
        url: "/subscription/subscribe",
        method: "POST",
        data: this.formatPayload(payload),
      });

      if (!subscribeResp || subscribeResp.message !== "Success") {
        response.error = i18n.t("subscription.payment.error");
      }
    } catch (error) {
      this.authService.logout();
      response.error = i18n.t("subscription.payment.error");
    }

    return response;
  }

  async updateMemberInfo() {
    const response = this.createDefaultResponseObj();

    try {
      const member = await this.getMember();

      store.dispatch(
        updateUserInfo(
          Object.assign(
            {},
            {
              isPartnerMember: member.data.partner ? true : false,
              paymentGateway: member.data.payment_gateway,
            },
            member.data
          )
        )
      );

      const actualPermissions = store.getState().auth.permissions;

      const permissions = this.authService.definePermissionsAfterLogin(
        member.data,
        actualPermissions
      );

      store.dispatch(updatePermissions(permissions));
    } catch (error) {
      console.error("Erro ao atualizar informações do membro");
    }

    return response;
  }

  createDefaultResponseObj() {
    return {
      error: null,
      success: null,
    };
  }

  getSubscriptionInfo() {
    return this.makeHttpRequest({
      url: "/subscription/subscribe",
    });
  }

  async updateSubscription(data) {
    const subscribeResp = await this.makeHttpRequest({
      url: "/subscription/subscribe",
      method: "PUT",
      data: this.formatPayload(data),
    });

    if (!subscribeResp || subscribeResp.message !== "Success") {
      throw new Error("Erro ao atualizar dados de pagamento");
    }
  }

  formatPayload(payload) {
    return {
      name: payload.name,
      number: payload.cardNumber,
      month: parseInt(payload.month),
      year: parseInt(payload.year),
      cvv: payload.cvv.trim(),
    };
  }

  deleteSubscription(reason) {
    return this.makeHttpRequest({
      url: "/subscription/subscribe",
      method: "DELETE",
      data: {
        reason,
      },
    });
  }

  deleteUser(id) {
    return this.makeHttpRequest({
      url: "/assisted/" + id,
      method: "DELETE",
    });
  }

  reactivateSubscription() {
    return this.makeHttpRequest({
      url: "/subscription/subscribe",
      method: "PATCH",
    });
  }
}

export default SubscriptionService;

import { LIST_CHECKME, FIND_CHECKME, UPDATE_CHECKME } from "../actions";

const initialState = {
  title: null,
  start_date: null,
  end_date: null,
  recurrency: null,
  message_text: null,
  message_voice: null,
  enabled: null,
  status: null,
};

export default function checkMe(state = initialState, action) {
  switch (action.type) {
    case LIST_CHECKME:
      return {
        ...state,
        title: action.payload.title,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        recurrency: action.payload.recurrency,
        message_text: action.payload.message_text,
        message_voice: action.payload.message_voice,
        enabled: action.payload.enabled,
        status: action.payload.status,
      };
    case FIND_CHECKME:
      return {
        ...state,
        title: action.payload.title,
        start_date: action.payload.start_date,
        recurrency: action.payload.recurrency,
        message_text: action.payload.message_text,
        message_voice: action.payload.message_voice,
        enabled: action.payload.enabled,
        status: action.payload.status,
      };
    case UPDATE_CHECKME:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload.permissions,
        },
      };
    default:
      return state;
  }
}

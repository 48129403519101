export default Object.freeze({
  CLIENTE: "CLIENTE",
  RECESSO: "RECESSO",
  RICHIESTA_PROVA_GRATUITA: "RICHIESTA_PROVA_GRATUITA",
  STEP_1_FORM: "STEP_1_FORM",
  STEP_2_FORM: "STEP_2_FORM",
  STEP_3_FORM: "STEP_3_FORM",
  STEP_4_FORM: "STEP_4_FORM",
  RIATTIVATO: "RIATTIVATO",
});

export class LanguageHelper {
  static getNormalizedCode(language) {
    const map = {
      pt: "pt-BR",
      en: "en-US",
      es: "es-ES",
      it: "it-IT",
    };

    return map[language] || map.en;
  }
}

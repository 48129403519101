import axios from "axios";
import HttpRequest from "../HttpService";
import { store } from "../../redux/store";
import { LanguageHelper } from "../../helpers/LanguageHelper";

class VoxMeApiService extends HttpRequest {
  constructor() {
    super(
      axios.create({
        baseURL: `${process.env.REACT_APP_VOXME_API_HOST}/api/v1`,
      })
    );

    this.axios.interceptors.request.use((config) => {
      const state = store.getState();
      if (!config.headers["x-api-key"] && state.auth && state.auth.authToken) {
        config.headers["x-api-key"] = state.auth.authToken;
      }

      if (state.i18n.language) {
        config.headers["Content-Language"] = LanguageHelper.getNormalizedCode(
          state.i18n.language
        );
      }
      return config;
    });
  }

  cleanGoogleToken(data) {
    if (!data.token_google) {
      delete data.token_google;
    }
  }

  async getMember(apiKey) {
    const config = {
      url: "/member/",
    };

    if (apiKey) {
      config.headers = {
        "x-api-key": apiKey,
      };
    }

    return this.makeHttpRequest(config);
  }

  createMember(data) {
    return this.saveMember({ ...data }, "post");
  }

  updateMember(data) {
    return this.saveMember({ ...data }, "put");
  }

  sendActionActiveCampaign({ action, data, language }) {
    const url = "/member/async-processing-webhook";

    const payload = {
      action,
      provider: "active-campaign",
      data,
    };

    if (
      language === "it" &&
      process.env.REACT_APP_ACTIVE_CAMPAIGN_TRACKING === "true"
    ) {
      return this.makeHttpRequest({
        url,
        method: "POST",
        data: payload,
      });
    }
  }

  saveMember(payload, method, assisted = false) {
    this.formatMemberPayload(payload);
    const url = assisted ? "/member/assisted/" : "/member/";

    return this.makeHttpRequest({
      url,
      method,
      data: payload,
    });
  }

  saveAssisted(payload, method) {
    this.formatMemberPayload(payload);
    const url = "/assisted/";
    return this.makeHttpRequest({
      url,
      method,
      data: payload,
    });
  }

  getNewAssisteds(method) {
    const url = "/assisted/";
    return this.makeHttpRequest({
      url,
      method,
    });
  }

  formatMemberPayload(data) {
    delete data.phone_masked;
    this.cleanGoogleToken(data);

    if (data.phone) {
      data.phone = data.phone.replace(/[^+\d]/g, "");
    }
  }

  createAssisted(data) {
    return this.saveMember({ ...data }, "post", true);
  }

  createNewAssisted(data) {
    return this.saveAssisted({ ...data }, "post", true);
  }

  getNewAssisted() {
    return this.getNewAssisteds("get", true);
  }

  updateAssisted(rota, data) {
    this.formatMemberPayload(data);
    const url = rota;
    return this.makeHttpRequest({
      url,
      method: "put",
      data,
    });
  }

  createContact(data) {
    return this.makeHttpRequest({
      url: "/contacts/",
      method: "post",
      data,
    });
  }

  async emailIsAvailable(email, action) {
    try {
      const response = await this.makeHttpRequest({
        url: "/member/check-email/" + email + "?action=" + action,
      });

      const userNotExistsCode = 1320002;
      return response.error_code === userNotExistsCode;
    } catch (error) {
      return false;
    }
  }

  async phoneIsAvailable(phone, action) {
    try {
      const response = await this.makeHttpRequest({
        url: "/member/check-phone/" + phone + "?action=" + action,
      });

      const userNotExistsCode = 1320002;
      return response.error_code === userNotExistsCode;
    } catch (error) {
      return false;
    }
  }

  getHistory(params = {}) {
    const config = {
      url: "/events/",
      params,
    };

    return this.makeHttpRequest(config);
  }
}

export default VoxMeApiService;

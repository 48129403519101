import React from "react";

class VoxMeInput extends React.Component {
  render() {
    let message = null;
    if (this.props.validations) {
      message = this.props.validator.message(
        this.props.name,
        this.props.value,
        this.props.validations
      );
    }

    const newProps = {
      type: this.props.type || undefined,
      placeholder: this.props.placeholder || undefined,
      name: this.props.name || undefined,
      value: this.props.value,
      onChange: this.props.onChange,
      onBlur: this.props.onBlur || undefined,
      className: `${this.props.className || ""} ${message && "vxm-error"}`,
      readOnly: this.props.readOnly || false,
      disabled: this.props.disabled || false,
      id: this.props.id || undefined,
      checked: this.props.checked || false,
      maxLength: this.props.maxLength || undefined,
      max: this.props.max || undefined,
      autoComplete: this.props.autocomplete || undefined,
      autoFocus: this.props.autoFocus || undefined,
    };

    let children = null;
    if (this.props.children) {
      children = React.Children.map(this.props.children, (child) => {
        return React.cloneElement(child, newProps);
      });
    }

    const label = this.props.label;

    return (
      <>
        {children ? children : <input {...newProps} />}
        {label && <label>{label}</label>}
        {message}
      </>
    );
  }
}

export default VoxMeInput;

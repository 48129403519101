import VoxMeFeatureApiService, {
  PaginationOptions,
} from "./features/VoxMeFeatureApiService";

export default class MedicationsService extends VoxMeFeatureApiService {
  async find(id) {
    return this.makeHttpRequest({
      url: "/medications",
      params: {
        id,
      },
    });
  }

  async getList(name, countryId, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const params = {
      name,
      per_page,
      order_by_column,
      order_by_direction,
      page,
    };

    if (countryId) {
      params.country_id = countryId;
    }

    const config = {
      url: "/medications",
      method: "GET",
      params,
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getTypes() {
    const config = {
      url: `/medications/types`,
      method: "GET",
    };
    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getUnits() {
    const config = {
      url: `/medications/units`,
      method: "GET",
    };
    const response = await this.makeHttpRequest(config);

    return response || null;
  }
}

import VoxMeFeatureApiService from "./VoxMeFeatureApiService";

export default class CountriesApiService extends VoxMeFeatureApiService {
  async getAll() {
    const config = {
      url: "/countries",
    };

    return this.makeHttpRequest(config);
  }
}

import { CHANGE_LANGUAGE } from "../actions/i18n.actions";

const initialState = {
  language: null,
};

export default function i18n(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.newLanguage,
      };
    default:
      return state;
  }
}

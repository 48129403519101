import HttpService from "../HttpService";
import Axios from "axios";
import VoxMeApiService from "./VoxMeApiService";
import { store } from "../../redux/store";
import { login, logout, updateAssistedSelected } from "../../redux/actions";
import { LanguageHelper } from "../../helpers/LanguageHelper";

class AuthService extends HttpService {
  constructor() {
    super(
      Axios.create({
        baseURL: `${process.env.REACT_APP_VOXME_API_HOST}/api/v1`,
      })
    );

    this.axios.interceptors.request.use((config) => {
      const state = store.getState();
      if (state.i18n.language) {
        config.headers["Content-Language"] = LanguageHelper.getNormalizedCode(
          state.i18n.language
        );
      }

      return config;
    });
  }

  async login(data) {
    const loginResponse = await this.makeHttpRequest({
      url: "/authorization/login",
      method: "POST",
      data: this.buildLoginPayload(data),
    });

    const voxMeApiService = new VoxMeApiService();
    const member = await voxMeApiService.getMember(loginResponse.data.token);

    const permissions = this.definePermissions(member.data, loginResponse.data);
    store.dispatch(
      login({
        authToken: loginResponse.data.token,
        userInfo: Object.assign(
          {},
          {
            isPartnerMember: member.data.partner ? true : false,
            paymentGateway: member.data.payment_gateway,
          },
          member.data
        ),
        permissions,
      })
    );

    store.dispatch(
      updateAssistedSelected(
        member.data.is_subscriber ? member.data.user_data : member.data
      )
    );

    return {
      authInfo: loginResponse.data,
      member: member.data,
      permissions,
    };
  }

  definePermissions(user, authInfo) {
    const permissions = {};
    if (!authInfo.active) {
      permissions.assisted = false;
      permissions.contacts = false;
      permissions.add_new_feats = false;
    } else if (!user.user_has_contacts || !user.account_complete) {
      permissions.assisted = true;
      permissions.contacts = true;
      permissions.add_new_feats = false;
    }

    return permissions;
  }

  definePermissionsAfterLogin(user, actualPermissions) {
    const permissions = { ...actualPermissions };
    if (user.user_has_contacts && user.account_complete) {
      permissions.add_new_feats = true;
    }

    return permissions;
  }

  buildLoginPayload(data) {
    let payload = null;
    if (data.token_google) {
      payload = {
        token_google: data.token_google,
      };
    } else {
      payload = {
        username: data.username,
        password: data.password,
        identifier_type: data.identifier_type,
      };
    }

    return payload;
  }

  logout() {
    store.dispatch(logout());
  }

  requestPasswordRecovery(username, identifier_type) {
    const route =
      identifier_type === "phone"
        ? "/authorization/password_recover_phone/"
        : "/authorization/password_recover/";
    return this.makeHttpRequest({
      url: route + username,
    });
  }

  checkPasswordRecoveryToken(token, identifier_type) {
    return this.makeHttpRequest({
      url: "/authorization/password_recover/",
      method: "PATCH",
      data: {
        recover_hash: token,
        identifier_type,
      },
    });
  }

  passwordRecovery(token, password, identifier_type) {
    return this.makeHttpRequest({
      url: "/authorization/password_recover/",
      method: "POST",
      data: {
        recover_hash: token,
        password,
        identifier_type,
      },
    });
  }
}

export default AuthService;

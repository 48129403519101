import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

import "./index.css";
import "./assets/sass/index.scss";
import "./assets/sass/home.scss";
import "./assets/sass/details.scss";
import "./assets/sass/menu.scss";
import "./assets/sass/side-menu.scss";
import "./assets/sass/breadcrumb.scss";
import "./assets/sass/select-input.scss";
import "./assets/sass/remindme.scss";
import "./assets/sass/internal-form.scss";
import "./assets/sass/followme.scss";
import "./assets/sass/assinante.scss";
import "./assets/sass/payment.scss";
import "./assets/sass/event-tracking.scss";
import "./assets/sass/login.scss";
import "./assets/sass/steps-theme.scss";
import "./assets/sass/my-warnings.scss";
import "./assets/sass/register.scss";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <React.Fragment>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

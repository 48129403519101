import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  UPDATE_USER_INFO,
  UPDATE_ASSISTED_SELECTED,
  UPDATE_PERMISSIONS,
} from "../actions";

const initialState = {
  authToken: null,
  assistedSelected: {},
  userInfo: {
    isPartnerMember: false,
  },
  permissions: {
    account: true,
    assisted: true,
    contacts: true,
    add_new_feats: true,
  },
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        authToken: action.payload.authToken,
        userInfo: { ...state.userInfo, ...action.payload.userInfo },
        permissions: {
          ...state.permissions,
          ...action.payload.permissions,
        },
      };
    case AUTH_LOGOUT:
      return initialState;
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.userInfo },
      };
    case UPDATE_ASSISTED_SELECTED:
      return {
        ...state,
        assistedSelected: action.payload.assistedSelected,
      };
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload.permissions,
        },
      };
    default:
      let _state = { ...state };
      if (
        Object.keys(_state.assistedSelected || {}).length === 0 &&
        Object.keys(_state.userInfo).length > 0
      ) {
        if (state?.userInfo?.is_subscriber === true) {
          _state.assistedSelected = state.userInfo.user_data;
        } else {
          _state.assistedSelected = state.userInfo;
        }

        return _state;
      } else {
        return state;
      }
  }
}

import React from "react";
import { Router as RouterDOM, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { initTracking, initTrackingGAds } from "../helpers/TrackingHelper";
import { pageView } from "../helpers/TrackingHelper";
import { connect } from "react-redux";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { changeLanguage } from "../redux/actions/i18n.actions";
import queryString from "querystring";

const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

history.listen((location) => {
  const path = buildPath(location);
  pageView(path);
});

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoad: false,
    };

    this.defineLanguage = this.defineLanguage.bind(this);
  }

  componentDidMount() {
    if (!this.state.initialLoad) {
      const path = buildPath(history.location);
      pageView(path);
      this.setState({ initialLoad: true });
    }

    const { utm_source, utm_campaign, utm_content } = this.getQueryParams();

    if (utm_campaign) {
      localStorage.setItem("utm_campaign", utm_campaign);
    }

    if (utm_source) {
      localStorage.setItem("utm_source", utm_source);
    }

    if (utm_content) {
      localStorage.setItem("utm_content", utm_content);
    }
  }

  componentWillMount() {
    const lang = this.updateLocation();
    this.handleLanguage(lang);
  }

  defineLanguage(key) {
    this.handleLanguage(key);
    this.updateLocation(`/${key}`);
  }

  getQueryParams() {
    return queryString.parse(window.location.search.replace("?", ""));
  }

  handleLanguage(key) {
    this.props.i18n.changeLanguage(key);
    this.props.dispatch(changeLanguage(key));
    this.updateMetaTags();
    this.defineZohoWidget(key);

    if (!this.state.initialLoad) {
      initTracking(
        key === "it"
          ? process.env.REACT_APP_GOOGLE_ANALYTICS_IT_TRACKING_ID
          : process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
        process.env.REACT_APP_FACEBOOK_PIXEL_TRACKING_ID
      );

      initTrackingGAds(
        key === "it"
          ? process.env.REACT_APP_GOOGLE_ADS_IT_TRACKING_ID
          : process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID
      );
    }
  }

  getDefaultLocation() {
    const defaultLanguage = "en";
    const browserLang = (window.navigator.language || "").substring(0, 2);
    if (this.props.currentLanguage) {
      return this.props.currentLanguage;
    } else if (this.props.i18n.options.resources.hasOwnProperty(browserLang)) {
      return browserLang;
    } else {
      return defaultLanguage;
    }
  }

  updateLocation(newHistory) {
    const languages = Object.keys(this.props.i18n.store.data);
    const defaultLanguage = this.getDefaultLocation();
    const { pathname, search, state } = history.location;
    const paths = history.location.pathname.split("/");
    const langPath = paths[1];

    if (newHistory) {
      history.push({
        pathname:
          paths.length > 2
            ? history.location.pathname.replace(`/${langPath}`, newHistory)
            : newHistory,
        search,
        state,
      });
      return history.location.pathname.split("/")[1];
    }
    if (langPath.length === 0) {
      history.push({
        pathname: `/${defaultLanguage}/`,
        search,
        state,
      });
      return defaultLanguage;
    } else if (langPath.length > 2) {
      history.push({
        pathname: `/${defaultLanguage}${pathname}`,
        search,
        state,
      });
      return defaultLanguage;
    } else if (!languages.includes(langPath)) {
      const replacedRoute = history.location.pathname.replace(
        langPath,
        defaultLanguage
      );
      history.push({
        pathname: replacedRoute,
        search,
        state,
      });
      return defaultLanguage;
    } else {
      return langPath;
    }
  }

  updateMetaTags() {
    document.head.querySelector(
      'meta[name="description"]'
    ).content = this.props.t("seo.description");

    document.head.querySelector('meta[name="keywords"]').content = this.props.t(
      "seo.keywords"
    );
  }

  defineZohoWidget(lang) {
    let script = document.getElementById("zsiqscript");
    if (script) {
      script.remove();
    }

    window.$zoho = {
      salesiq: {
        widgetcode:
          lang === "pt"
            ? process.env.REACT_APP_PT_ZOHO_WIDGET_CODE
            : lang === "it"
            ? process.env.REACT_APP_IT_ZOHO_WIDGET_CODE
            : process.env.REACT_APP_ZOHO_WIDGET_CODE,
        values: {},
        ready: function () {},
      },
    };

    script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zoho.com/widget";
    const target = document.getElementsByTagName("script")[0];
    target.parentNode.insertBefore(script, target);
  }

  render() {
    const { search, state } = history.location;
    return (
      <RouterDOM history={history}>
        <Switch>
          <Route path="/:locale/admin">
            <PrivateRouter
              {...this.props}
              defineLanguage={this.defineLanguage}
              history={history}
            />
          </Route>

          <Route
            exact
            path="/pt"
            render={() => {
              const sitePt =
                window.location.hostname === "localhost"
                  ? "https://www.alpha.voxme.me/pt"
                  : "/pt";

              window.location = sitePt;
            }}
          />

          <Route
            exact
            path="/it"
            render={() => {
              const siteIt =
                window.location.hostname === "localhost"
                  ? "https://www.alpha.voxme.me/it"
                  : "/it";

              window.location = siteIt;
            }}
          />

          <Route path="/:locale/">
            <PublicRouter
              {...this.props}
              defineLanguage={this.defineLanguage}
              history={history}
            />
          </Route>

          <Route exact path={`/`}>
            <Redirect
              to={{
                pathname: `/${this.props.currentLanguage}/`,
                search,
                state,
              }}
            />
          </Route>
        </Switch>
      </RouterDOM>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoggedIn: !!store.auth.authToken,
    currentLanguage: store.i18n.language,
  };
};

export default connect(mapStateToProps)(Router);

import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { withTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import { verifyCpfCnpj } from "../helpers/IsValidCpfCnpj";

export default function withSubscription(WrappedComponent) {
  const NewComponent = class extends React.Component {
    constructor(props) {
      super(props);

      this.validator = new SimpleReactValidator({
        className: "vxm-error text-left",
        locale: props.i18n.language,
        validators: {
          phone: {
            message: "The phone number is invalid.",
            rule: (val, params, validator) => {
              return isValidPhoneNumber(val);
            },
          },
          idDocument: {
            message: "Favor inserir um CPF ou CNPJ válido.",
            rule: (val, params, validator) => {
              const isValid = verifyCpfCnpj(val);
              return isValid.isValid;
            },
          },
          fullName: {
            message: "Por favor, insira nome e sobrenome.",
            rule: (val) => {
              return !(
                this.state.name.split(" ").length < 2 ||
                this.state.name.split(" ")[1].length === 0
              );
            },
          },
        },
      });

      this.validateForm = this.validateForm.bind(this);
    }

    handleChange(e) {
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      this.setState({
        [e.target.name]: value,
      });
    }

    validateForm() {
      if (this.validator.allValid()) {
        return true;
      } else {
        this.validator.showMessages();
        this.forceUpdate();
        return false;
      }
    }

    render() {
      return (
        <WrappedComponent
          validator={this.validator}
          handleChange={this.handleChange}
          validateForm={this.validateForm}
          {...this.props}
        />
      );
    }
  };

  return withTranslation()(NewComponent);
}

import VoxMeFeatureApiService, {
  PaginationOptions,
} from "./features/VoxMeFeatureApiService";
import moment from "moment";

export default class RemindMeService extends VoxMeFeatureApiService {
  async getList(assisted_id, search_date, options = {}) {
    const { per_page, order_by_column, order_by_direction, page } = {
      ...new PaginationOptions(),
      ...options,
    };

    const config = {
      url: "/remind",
      method: "GET",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
        assisted_id,
        // search_date,
        utcOffset: moment().utcOffset() / 60,
      },
    };

    return this.makeHttpRequest(config);
  }

  async getById(assisted_id, id) {
    const config = {
      url: `/remind/${id}`,
      method: "GET",
      params: {
        assisted_id,
      },
    };

    return this.makeHttpRequest(config);
  }

  async stopAlarm(reminder_id) {
    const config = {
      url: `/remind/${reminder_id}/alarm`,
      method: "DELETE",
    };
    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getHistoryById(id, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const config = {
      url: `/remind/${id}/history`,
      method: "GET",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
      },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async create(data) {
    const config = {
      url: `/remind`,
      method: "POST",
      data,
    };

    return this.makeHttpRequest(config);
  }

  async update(id, data) {
    const config = {
      url: `/remind/${id}`,
      method: "PUT",
      data,
    };

    return this.makeHttpRequest(config);
  }

  async delete(id) {
    const config = {
      url: `/remind/${id}`,
      method: "DELETE",
    };

    return this.makeHttpRequest(config);
  }

  getContact(assistedId = null) {
    const config = {
      url: assistedId
        ? `remind/contact?assisted_id=${assistedId}`
        : `remind/contact`,
      method: "GET",
    };

    return this?.makeHttpRequest(config);
  }

  updateContactEmergency(id, assistedId = null) {
    const config = {
      url: `remind/contact`,
      method: "PUT",
      data: {
        contact_id: id,
      },
    };

    if (assistedId) {
      config.data.assisted_id = assistedId;
    }

    return this?.makeHttpRequest(config);
  }
}

export const LIST_CHECKME = "LIST_CHECKME";
export const FIND_CHECKME = "FIND_CHECKME";
export const UPDATE_CHECKME = "UPDATE_CHECKME";

export function listCheckMe(checkMe) {
  return {
    type: LIST_CHECKME,
    payload: { checkMe },
  };
}

export function findCheckMe(checkMe) {
  return {
    type: FIND_CHECKME,
    payload: { checkMe },
  };
}

export function updateCheckMe(checkMe) {
  return {
    type: UPDATE_CHECKME,
    payload: { checkMe },
  };
}

import VoxMeFeatureApiService, {
  PaginationOptions,
} from "./features/VoxMeFeatureApiService";

export default class CheckMeService extends VoxMeFeatureApiService {
  async getList(assisted_id, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const config = {
      url: "/check/",
      method: "get",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
        assisted_id,
      },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getById(id) {
    const config = {
      url: `/check/${id}`,
      method: "get",
    };

    return this.makeHttpRequest(config);
  }

  async getHistoryById(id, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const config = {
      url: `/check/${id}/history`,
      method: "get",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
      },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async create(data) {
    const config = {
      url: `/check/`,
      method: "post",
      data: { ...data },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async edit(data, id) {
    const config = {
      url: `/check/${id}`,
      method: "put",
      data: { ...data },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async remove(id) {
    const config = {
      url: `/check/${id}`,
      method: "delete",
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }
}

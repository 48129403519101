import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ReactLoading from "react-loading";

const Home = lazy(() =>
  import("../containers/Home").then((module) => ({ default: module.Home }))
);
const Login = lazy(() => import("../containers/Login"));
const Subscription = lazy(() =>
  import("../containers/Subscription/Subscription")
);
const PasswordRecovery = lazy(() =>
  import("../containers/PasswordRecovery/PasswordRecovery")
);
const RequestPasswordRecovery = lazy(() =>
  import("../containers/PasswordRecovery/RequestPasswordRecovery")
);
const EventTracking = lazy(() =>
  import("../containers/EventTracking/EventTracking")
);
const CheckEmail = lazy(() => import("../containers/CheckEmail"));
const LandingSubscription = lazy(() =>
  import("../containers/LandingSubscription")
);
const LandingContact = lazy(() => import("../containers/LandingContact"));

const LandingWomanAlone = lazy(() => import("../containers/LandingWomanAlone"));

const Error404 = lazy(() =>
  import("../components").then((module) => ({ default: module.Error404 }))
);

class PublicRouter extends React.Component {
  handleRedirectStore = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let platform = /android/i.test(userAgent)
      ? "https://play.google.com/store/apps/details?id=com.voxme"
      : "https://apps.apple.com/br/app/voxme/id1497817176";

    window.location.replace(platform);

    return null;
  };

  render() {
    const { currentLanguage } = this.props;
    return (
      <Suspense
        fallback={
          <ReactLoading
            className="loading"
            type="bars"
            color="#111"
            height={"100%"}
            width={"100%"}
          />
        }
      >
        <Switch>
          <Route exact path={`/${currentLanguage}/`}>
            <Home {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/auth`}>
            <CheckEmail {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/subscribe`}>
            <CheckEmail {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/sign`}>
            <Subscription {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/register`}>
            <Subscription {...this.props} />
          </Route>
          <Route path={`/pt/subscribe-woman-alone`}>
            <Subscription {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/finish-subscription`}>
            <LandingSubscription {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/onboarding-contact`}>
            <LandingContact {...this.props} />
          </Route>
          <Route path={`/pt/landing-woman-alone`}>
            <LandingWomanAlone {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/login`}>
            <Login {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/password-recover/:token`}>
            <PasswordRecovery {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/request-password-recovery`}>
            <RequestPasswordRecovery {...this.props} />
          </Route>
          {/* TODO: remover após atualizar o backend */}
          <Route
            exact
            path={`/${currentLanguage}/assisted-location/:token`}
            render={(props) => (
              <Redirect
                to={`/${currentLanguage}/event/tracking/${props.match.params.token}`}
              />
            )}
          />
          <Route path={`/${currentLanguage}/event/tracking/:token`}>
            <EventTracking {...this.props} />
          </Route>
          <Route path={`/${currentLanguage}/event/location/:token`}>
            <EventTracking {...this.props} />
          </Route>
          <Route
            path={`/${currentLanguage}/redirect-store`}
            component={(props) => this.handleRedirectStore()}
          />
          <Route exact path="/">
            <Home {...this.props} />
          </Route>
          <Route path="*">
            <Error404 t={this.props.t} />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default PublicRouter;

import VoxMeFeatureApiService from "./VoxMeFeatureApiService";
import moment from "moment";

export default class SchedulerApiService extends VoxMeFeatureApiService {
  async getSummary(searchDate, assistedId) {
    const config = {
      url: "/schedulers/summary",
      params: {
        search_date: searchDate,
        assisted_id: assistedId,
        utcOffset: moment().utcOffset() / 60,
      },
    };

    return this.makeHttpRequest(config);
  }
}

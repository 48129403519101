class GoogleMapsService {
  static getAddress(latitude, longitude) {
    return fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        latitude +
        "," +
        longitude +
        "&key=" +
        process.env.REACT_APP_GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status !== "OK") {
          throw new Error(responseJson.error_message);
        }

        const address = responseJson.results[0];
        return {
          formatted_address: address.formatted_address,
          zip_code: address.address_components.find((ac) =>
            ac.types.includes("postal_code")
          )?.short_name,
          city: address.address_components.find((ac) =>
            ac.types.includes("administrative_area_level_2")
          )?.short_name,
          country: address.address_components.find((ac) =>
            ac.types.includes("country")
          ).short_name,
        };
      });
  }
}

export default GoogleMapsService;

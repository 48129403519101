import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import pt from "./locales/pt.json";
import es from "./locales/es.json";
import it from "./locales/it.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    pt: {
      translation: pt,
    },
    es: {
      translation: es,
    },
    it: {
      translation: it,
    },
  },
  lng: "pt",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { store } from "../redux/store";
import { showSpinner, hideSpinner } from "../redux/actions/spinner.actions";

export default class HttpService {
  constructor(axios) {
    if (!axios) {
      throw new Error("Setup do axios não fornecido");
    }

    this.axios = axios;

    this.axios.interceptors.request.use((config) => {
      store.dispatch(showSpinner());
      return config;
    });

    this.axios.interceptors.response.use(
      (resp) => {
        store.dispatch(hideSpinner());
        return resp;
      },
      (err) => {
        store.dispatch(hideSpinner());
        return err;
      }
    );
  }

  async makeHttpRequest(config) {
    try {
      const httpResponse = await this.axios.request(config);
      if (httpResponse instanceof Error) {
        throw httpResponse;
      }

      return httpResponse.data;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      const reponsePayload =
        typeof e.response.data === "string"
          ? e.response.data
          : JSON.stringify(e.response.data);

      e.message = `
        Error: ${e.message},
        StatusCode: ${e.response.status},
        Request Config: ${JSON.stringify(config)},
        Response Payload: ${reponsePayload}
      `;

      if (
        e.response &&
        e.response.data &&
        typeof e.response.data === "object" &&
        e.response.data.error_code
      ) {
        e.error_code = e.response.data.error_code;
      }

      throw e;
    }
  }
}

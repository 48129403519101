import VoxMeApiService from "./VoxMeApiService";
import moment from "moment";
import i18n from "../../i18n";
import { StorageKeys } from "../../enums";

class EventService extends VoxMeApiService {
  async getTracking(token) {
    let tracking = null;
    const response = await this.makeHttpRequest({
      url: `/events/tracking/${token}`,
    });

    tracking = response.data;

    tracking.is_running = tracking.status === "running";
    tracking.type = i18n.t(`shared.${tracking.type}`);
    tracking.start_latitude = parseFloat(tracking.start_latitude);
    tracking.start_longitude = parseFloat(tracking.start_longitude);
    tracking.end_latitude = tracking.end_latitude
      ? parseFloat(tracking.end_latitude)
      : null;
    tracking.end_longitude = tracking.end_longitude
      ? parseFloat(tracking.end_longitude)
      : null;

    tracking.moved =
      tracking.end_latitude &&
      tracking.end_longitude &&
      tracking.end_latitude !== tracking.start_latitude &&
      tracking.end_longitude !== tracking.start_longitude;

    const endTime = moment
      .unix(tracking.start_time)
      .add(tracking.track_time, "minutes");
    const trackingTime = endTime.diff(moment(), "miliseconds");

    if (!tracking.end_address && tracking.end_address !== "") {
      console.log("last location");
      let lastLocation = JSON.parse(
        localStorage.getItem(StorageKeys.LAST_EVENT_TRACKING_LOCATION) || "{}"
      );

      if (lastLocation.id === tracking.id) {
        tracking = { ...tracking, ...lastLocation };
      }
    }

    if (trackingTime > 0) {
      tracking.expires_in = trackingTime;
    } else {
      tracking.expires_in = 0;
      tracking.status = "finished";
      tracking.is_running = false;

      if (!tracking.end_address) {
        tracking.end_latitude = tracking.start_latitude;
        tracking.end_longitude = tracking.start_longitude;
        tracking.end_address = tracking.start_address;
      }
    }

    return tracking;
  }
}

export default EventService;

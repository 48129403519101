import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { store } from "../redux/store";

export const initTracking = (trackingIdGa, trackingIdPixel) => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING === "true") {
    ReactGA.initialize(trackingIdGa);
  }

  if (process.env.REACT_APP_FACEBOOK_PIXEL_TRACKING === "true") {
    ReactPixel.init(trackingIdPixel);
  }
};

export const pageView = (path) => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING === "true") {
    ReactGA.set({ page: path }); // Update the user's current page
    ReactGA.pageview(path); // Record a pageview for the given page
  }

  if (process.env.REACT_APP_FACEBOOK_PIXEL_TRACKING === "true") {
    ReactPixel.pageView();
  }
};

export const initTrackingGAds = (trackingIdADS) => {
  if (process.env.REACT_APP_GOOGLE_ADS_TRACKING === "true") {
    const gtmScript = document.createElement("script");
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${trackingIdADS}`;
    gtmScript.async = true;

    const gtmConfig = document.createElement("script");

    const jsScript = document.createTextNode(
      `window.dataLayer = window.dataLayer || []; function gtm(data){dataLayer.push(data);} gtm({'gtm.start':new Date().getTime(),event:'gtm.js'});`
    );

    gtmConfig.appendChild(jsScript);

    document.head.appendChild(gtmScript);
    document.head.appendChild(gtmConfig);
  }
};

export const eventConversion = () => {
  if (process.env.REACT_APP_GOOGLE_ADS_TRACKING === "true") {
    if (store.getState().i18n.language === "it") {
      window.gtm({ event: "conversion" });
    } else {
      window.gtm({
        event: "conversion",
        send_to: process.env.REACT_APP_GOOGLE_ADS_EVENT_CONVERSION_SEND_TO,
      });
    }
  }
};

export const gtmEvent = (eventDesc) => {
  if (process.env.REACT_APP_GOOGLE_ADS_TRACKING === "true") {
    window.gtm({ event: eventDesc });
  }
};

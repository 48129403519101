import React from "react";
import { withTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactLoading from "react-loading";
import { store, persistor } from "./redux/store";
import Router from "./routes/Router";
import VoxMeModal from "./components/VoxMeModal";
import setupValidationLanguages from "./locales/validator";
import "./App.css";
import { AwsIotService } from "./services";
import { hideSpinner } from "./redux/actions/spinner.actions";

setupValidationLanguages();
AwsIotService.configure();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        show: false,
      },
      loading: false,
    };

    this.changeModalSettings = this.changeModalSettings.bind(this);
  }

  changeModalSettings(modalSettings) {
    this.setState({ modal: modalSettings });
  }

  componentDidMount() {
    persistor.subscribe(() => {
      if (store.getState().spinner.show) {
        store.dispatch(hideSpinner());
      }
    });

    store.subscribe(() => {
      this.setState({ loading: store.getState().spinner.show });
    });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {this.state.loading && (
            <ReactLoading
              className="loading"
              type="bars"
              color="#111"
              height={"100%"}
              width={"100%"}
            />
          )}

          <VoxMeModal
            {...this.state.modal}
            handleClose={() => this.changeModalSettings({ show: false })}
          />

          <Router {...this.props} modal={this.changeModalSettings} />
        </PersistGate>
      </Provider>
    );
  }
}

export default withTranslation()(App);

import React from "react";
import { Modal, Button } from "react-bootstrap";
import withFormValidation from "../hoc/withFormValidation";
import VoxMeInput from "./Forms/VoxMeInput";

class VoxMeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };

    this.handleChange = this.props.handleChange.bind(this);
  }

  handleConfirm(action = 1) {
    if (this.props.inputProps && !this.props.validateForm()) {
      return;
    }

    if (action === 1) {
      this.props.primaryAction
        ? this.props.primaryAction(this.state.inputValue)
        : this.props.handleClose();
    } else {
      this.props.secondaryAction
        ? this.props.secondaryAction(this.state.inputValue)
        : this.props.handleClose();
    }

    if (this.props.inputProps) {
      this.setState({ inputValue: "" });
    }
  }

  render() {
    this.props.validator.purgeFields();
    const routeCheck = window.location.pathname.split("/")[2];
    const isRegisterFlow =
      routeCheck === "subscribe" || routeCheck === "register";

    return (
      <Modal
        show={this.props.show}
        backdrop={this.props.closeable === false ? "static" : true}
        onHide={this.props.handleClose}
        className="modal fade internas vox-modal"
        centered
      >
        <Modal.Header closeButton={this.props.closeable !== false}>
          <Modal.Title as="h3">{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.text}</p>
          {this.props.inputProps && (
            <VoxMeInput
              name="inputValue"
              value={this.state.inputValue}
              onChange={this.handleChange}
              validator={this.props.validator}
              validations={this.props.inputProps.validations || ""}
              className="form-control"
            >
              {this.props.inputProps.type === "textarea" ? (
                <textarea rows="4"></textarea>
              ) : (
                <input type={this.props.inputProps.type} />
              )}
            </VoxMeInput>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => this.handleConfirm(2)}
            className={isRegisterFlow ? "button-light-register" : ""}
          >
            {this.props.secondaryActionText
              ? this.props.secondaryActionText
              : this.props.t(
                  "subscription.landingSubscription.primaryActionButton"
                )}
          </Button>

          <Button
            variant={isRegisterFlow ? "" : "dark"}
            onClick={() => this.handleConfirm()}
            className={isRegisterFlow ? "button-gradient-register" : ""}
          >
            {this.props.confirmText ? this.props.confirmText : "OK"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withFormValidation(VoxMeModal);

import {
  CHOOSE_USER,
  SET_SUBSCRIBER_DATA,
  SET_PAYMENT_DATA,
  SET_ASSISTED_DATA,
  BACK_SUBSCRIPTION_STEP,
  CLEAR_SUBSCRIPTION,
  GOTO_SUBSCRIPTION_STEP,
  SET_CONTACTS_DATA,
  BACK_CONTACTS_STEP,
  AFTER_CREATE_SUBSCRIPTION,
  SET_IDENTIFIER_TYPE,
} from "../actions";
import { AccountFor, SubscriptionSteps } from "../../enums";
import { SubscriptionFlowHelper } from "../../helpers/SubscriptionFlowHelper";

const initialState = {
  user: null,
  payment: null,
  assisted: null,
  subscriber: null,
  contacts: null,
  currentStep: SubscriptionSteps.CHOOSE_USER,
};

export default function subscription(state = initialState, action) {
  switch (action.type) {
    case CHOOSE_USER:
      return {
        ...state,
        user: action.payload.user,
        currentStep: SubscriptionSteps.CREATE_SUBSCRIBER,
      };
    case SET_SUBSCRIBER_DATA:
      let newStep = SubscriptionSteps.CREATE_SUBSCRIPTION; // contatos

      if (state.user === AccountFor.OTHER) {
        newStep = SubscriptionSteps.CREATE_ASSISTED;
      }
      // } else if (SubscriptionFlowHelper.isSignFlow()) {
      //   newStep = SubscriptionSteps.FILL_PAYMENT_INFO;
      // }

      return {
        ...state,
        subscriber: action.payload.subscriber,
        assisted:
          state.user === AccountFor.ME
            ? { ...action.payload.subscriber, self_assist: true }
            : state.assisted,
        currentStep: newStep,
      };
    case SET_ASSISTED_DATA:
      let nextStep = SubscriptionSteps.CREATE_SUBSCRIPTION; // contatos

      // if (SubscriptionFlowHelper.isSignFlow()) {
      //   nextStep = SubscriptionSteps.FILL_PAYMENT_INFO;
      // }

      return {
        ...state,
        assisted: action.payload.assisted,
        currentStep: nextStep,
      };
    case SET_CONTACTS_DATA:
      const newState = {
        ...state,
        contacts: action.payload.contacts,
      };

      // if (!SubscriptionFlowHelper.isPartnershipFlow()) {
      //   newState.currentStep = SubscriptionSteps.FILL_PAYMENT_INFO;
      // }

      return newState;
    case SET_PAYMENT_DATA:
      return {
        ...state,
        payment: action.payload.payment,
        currentStep: state.user === SubscriptionSteps.CREATE_SUBSCRIPTION, // CREATE_SUBSCRIBER
      };
    case GOTO_SUBSCRIPTION_STEP:
      return {
        ...state,
        currentStep: action.payload.step,
      };
    case AFTER_CREATE_SUBSCRIPTION:
      if (SubscriptionFlowHelper.isSignFlow()) {
        return {
          ...state,
          currentStep: SubscriptionSteps.CREATE_SUBSCRIPTION, // contatos
        };
      }

      return initialState;
    case CLEAR_SUBSCRIPTION:
      return initialState;
    case BACK_SUBSCRIPTION_STEP:
      let backStep = state.currentStep - 1;
      if (
        SubscriptionFlowHelper.isSignFlow() &&
        state.currentStep === SubscriptionSteps.FILL_PAYMENT_INFO
      ) {
        backStep =
          state.user === AccountFor.ME
            ? SubscriptionSteps.CREATE_SUBSCRIBER
            : SubscriptionSteps.CREATE_ASSISTED;
      }

      return {
        ...state,
        currentStep: backStep,
      };
    case BACK_CONTACTS_STEP:
      return {
        ...state,
        currentStep:
          state.user === AccountFor.ME
            ? SubscriptionSteps.CREATE_SUBSCRIBER
            : SubscriptionSteps.CREATE_ASSISTED,
      };
    case SET_IDENTIFIER_TYPE:
      return {
        ...state,
        subscriber: {
          ...state.subscriber,
          identifier_type: action.payload.identifier_type,
        },
      };

    default:
      return state;
  }
}

import VoxMeFeatureApiService, {
  PaginationOptions,
} from "./features/VoxMeFeatureApiService";

export default class FollowMeService extends VoxMeFeatureApiService {
  async getList(assisted_id, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const config = {
      url: "/follow/",
      method: "get",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
        assisted_id,
      },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getById(id) {
    const config = {
      url: `/follow/${id}`,
      method: "get",
    };
    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async getHistoryById(id, options = new PaginationOptions()) {
    const { per_page, order_by_column, order_by_direction, page } = options;

    const config = {
      url: `/follow/${id}/history`,
      method: "get",
      params: {
        per_page,
        order_by_column,
        order_by_direction,
        page,
      },
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async create(data) {
    const config = {
      url: `/follow`,
      method: "post",
      data,
    };

    try {
      const response = await this.makeHttpRequest(config);
      return response || null;
    } catch (error) {
      console.log(error.response);
    }
  }

  async update(data, id) {
    const config = {
      url: `/follow/${id}`,
      method: "put",
      data,
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }

  async remove(id) {
    const config = {
      url: `/follow/${id}`,
      method: "delete",
    };

    const response = await this.makeHttpRequest(config);

    return response || null;
  }
}

import SimpleReactValidator from "simple-react-validator";

import pt from "./pt.json";
import en from "./en.json";
import es from "./es.json";
import it from "./it.json";

const setupValidationLanguages = () => {
  SimpleReactValidator.addLocale("pt", pt);
  SimpleReactValidator.addLocale("en", en);
  SimpleReactValidator.addLocale("es", es);
  SimpleReactValidator.addLocale("it", it);
};

export default setupValidationLanguages;

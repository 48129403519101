export const CHOOSE_USER = "CHOOSE_USER";
export const SET_SUBSCRIBER_DATA = "SET_SUBSCRIBER_DATA";
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
export const SET_ASSISTED_DATA = "SET_ASSISTED_DATA";
export const SET_CONTACTS_DATA = "SET_CONTACTS_DATA";
export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";
export const GOTO_SUBSCRIPTION_STEP = "GOTO_SUBSCRIPTION_STEP";
export const BACK_SUBSCRIPTION_STEP = "BACK_SUBSCRIPTION_STEP";
export const BACK_CONTACTS_STEP = "BACK_CONTACTS_STEP";
export const AFTER_CREATE_SUBSCRIPTION = "AFTER_CREATE_SUBSCRIPTION";
export const SET_IDENTIFIER_TYPE = "SET_IDENTIFIER_TYPE";

export function chooseUser(user, step = null) {
  return {
    type: CHOOSE_USER,
    payload: { user, step },
  };
}

export function setIdentifierType(identifier_type) {
  return {
    type: SET_IDENTIFIER_TYPE,
    payload: { identifier_type },
  };
}

export function setSubscriberData(subscriber) {
  return {
    type: SET_SUBSCRIBER_DATA,
    payload: { subscriber },
  };
}

export function setPaymentData(payment) {
  return {
    type: SET_PAYMENT_DATA,
    payload: { payment },
  };
}

export function setAssistedData(assisted) {
  return {
    type: SET_ASSISTED_DATA,
    payload: { assisted },
  };
}

export function setContactsData(contacts) {
  return {
    type: SET_CONTACTS_DATA,
    payload: { contacts },
  };
}

export function afterCreateSubscription() {
  return {
    type: AFTER_CREATE_SUBSCRIPTION,
  };
}

export function clearSubscriptionFlow() {
  return {
    type: CLEAR_SUBSCRIPTION,
  };
}

export function goToSubscriptionStep(step) {
  return {
    type: GOTO_SUBSCRIPTION_STEP,
    payload: { step },
  };
}

export function backSubscriptionStep() {
  return {
    type: BACK_SUBSCRIPTION_STEP,
  };
}

export function backContactStep() {
  return {
    type: BACK_CONTACTS_STEP,
  };
}

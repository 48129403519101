export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_ASSISTED_SELECTED = "UPDATE_ASSISTED_SELECTED";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

export function login(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function logout() {
  return {
    type: AUTH_LOGOUT,
    authToken: null,
  };
}

export function updateUserInfo(userInfo) {
  return {
    type: UPDATE_USER_INFO,
    payload: { userInfo },
  };
}

export function updateAssistedSelected(assistedInfo) {
  return {
    type: UPDATE_ASSISTED_SELECTED,
    payload: { assistedSelected: assistedInfo },
  };
}

export function updatePermissions(permissions) {
  return {
    type: UPDATE_PERMISSIONS,
    payload: { permissions },
  };
}

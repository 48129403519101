import { UPDATE_USAGE_SETTING } from "../actions";

const initialState = {
  expandInfos: [],
};

export default function usageSettings(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USAGE_SETTING:
      if (state.expandInfos.includes(action.payload)) {
        return {
          ...state,
          expandInfos: state.expandInfos.filter(
            (item) => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          expandInfos: [...state.expandInfos, action.payload],
        };
      }
    default:
      return state;
  }
}

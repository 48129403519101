import { combineReducers } from "redux";
import auth from "./auth.reducer";
import subscription from "./subscription.reducer";
import i18n from "./i18n.reducer";
import spinner from "./spinner.reducer";
import usageSettings from "./usageSettings.reducer";
import checkMe from "./checkMe.reducer";

export default combineReducers({
  auth,
  subscription,
  i18n,
  spinner,
  usageSettings,
  checkMe,
});

import VoxMeApiService from "./VoxMeApiService";

class ContactService extends VoxMeApiService {
  handleContacts(contacts, assistedId = null, sendNotifications = true) {
    const toDelete = contacts.filter((c) => c.id && !c.name);
    const toUpdate = contacts.filter((c) => c.id && c.name);
    const toCreate = contacts.filter((c) => !c.id && c.name);

    const reqs = toDelete
      .map((c) => this.delete(c.id, assistedId))
      .concat(toUpdate.map((c) => this.update(c.id, c, assistedId)))
      .concat(
        toCreate.map((c) => this.create(c, assistedId, sendNotifications))
      );

    return Promise.all(reqs);
  }

  async handleContactsNewUser(contacts) {
    const newAssisted = await this.getNewAssisted();
    const newUser = newAssisted.data[newAssisted.data.length - 1];
    var toCreate = contacts.map((c) => this.createForNewUser(c, newUser.id));
    return Promise.all(toCreate);
  }

  get(id = null) {
    return this.makeHttpRequest({
      url: "/contacts/" + (id || ""),
      method: "GET",
    });
  }

  findByAssistedID(assistedId = null) {
    return this.makeHttpRequest({
      url: `/contacts/`,
      params: this.handleAssistedParam(assistedId),
      method: "GET",
    });
  }

  handleAssistedParam(assistedId) {
    return assistedId ? { assisted_id: assistedId } : null;
  }

  create(data, assistedId = null, sendNotifications = true) {
    return this.makeHttpRequest({
      url: `/contacts/`,
      method: "POST",
      params: this.handleAssistedParam(assistedId),
      data: { ...data, send_notifications: sendNotifications },
    });
  }

  createForNewUser(data, id) {
    return this.makeHttpRequest({
      url: "/contacts/",
      method: "POST",
      params: this.handleAssistedParam(id),
      data,
    });
  }

  update(id, data, assistedId = null) {
    const payload = { ...data };
    if (payload.hasOwnProperty("assisted_id") && !payload.assisted_id) {
      delete payload.assisted_id;
    }

    if (assistedId) {
      payload.assisted_id = assistedId;
    }
    return this.makeHttpRequest({
      url: `/contacts/${id}`,
      method: "PUT",
      params: this.handleAssistedParam(assistedId),
      data: payload,
    });
  }

  delete(id, assistedId = null) {
    return this.makeHttpRequest({
      url: `/contacts/${id}`,
      params: this.handleAssistedParam(assistedId),
      method: "DELETE",
    });
  }
}

export default ContactService;

import VoxMeApiService from "./VoxMeApiService";

export default class MainAssistedService extends VoxMeApiService {
  async get() {
    const response = await this.makeHttpRequest({
      url: `/member/assisted/`,
    });

    return response.data || null;
  }
}
